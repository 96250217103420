import React from 'react';

export default function Carrot({ color = '#161615', maskColor='none', ...props }) {
  return (
    <svg
      {...props}
      width="281"
      height="520"
      viewBox="0 0 281 520"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M116.115 468.38H163.045" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M77.8745 485.2H165.984" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.8047 515.63C16.8047 515.63 16.8047 402.78 96.9847 402.78C96.9847 402.78 168.265 402.78 168.265 515.63" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M97.7646 402.78C97.7646 402.78 98.9946 157.55 267.765 2C296.905 29.43 261.245 63.83 234.625 71.25C225.015 73.92 235.955 93.49 218.625 113.5C201.715 133.5 185.705 126.4 179.925 135.75C174.145 145.1 178.145 173.95 165.245 190.04C152.345 206.13 141.325 168.93 97.7646 402.78Z" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M98.8647 401.57C98.8647 401.57 79.7247 285.83 93.0947 258.2C106.465 230.57 135.095 229.82 129.975 187.66C126.105 152.38 85.9747 172.84 105.575 140.76C125.175 108.68 114.065 78.6898 89.9547 85.1498C87.9547 85.6898 92.2347 48.6898 53.0847 52.1498C13.9347 55.6098 23.0147 94.9698 23.0147 94.9698C-1.0853 101.47 -7.4353 127.46 19.8547 153.34C47.1447 179.22 12.2247 178.62 15.2347 202.28C21.6847 247.02 44.6047 239.28 64.7047 262.51C84.8047 285.74 96.8047 402.79 96.8047 402.79" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M16 517.5L15.5002 517L15.5 520H170V516.5L169 517.5H168L167 517L166.5 516V512L166 508.5L19 509L18.5 512V514V516L18 517L17.5 517.5H16.5H16Z"
        fill={maskColor}
      />
    </svg>
  );
}
