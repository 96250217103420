import React from 'react';
import PortableText from './portableText';
import CTALink from './CTALink';
import Image from './Image';

function Hero({ image, heading, tagline, cta }) {
  return (
    <div className="lg:container lg:mx-auto px-5 md:px-3 flex flex-wrap flex-col items-center relative">
      {/* Right col */}
      <div className="w-full md:w-11/12 text-center self-start md:self-center">
        <Image image={image} aspectRatio={16 / 9}/>
      </div>
      {/* Left col */}
      {heading || tagline ?
        <div
          className="flex flex-col w-full md:w-2/6 justify-center items-start text-center md:text-left z-10 bg-green p-5 md:absolute md:right-0 md:mt-12 md:mr-6 lg:mr-0">
          <h1 className="mb-2 text-5xl font-bold leading-tight text-white">{heading}</h1>
          <div className="leading-normal text-2xl mb-2 ml-4  text-justify text-gray-800">
            <PortableText blocks={tagline}/>
          </div>
          {cta && cta.title && (
            <CTALink
              {...cta}
              buttonActionClass="mx-auto ml-4 hover:underline bg-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
            />
          )}
        </div>
        : null
      }
    </div>
  );
}

export default Hero;
