import React from 'react';
import SpringLightbox from 'react-spring-lightbox';
import { imageUrlFor } from '../lib/image-url';
import { IoIosClose, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { animated, useTransition } from '@tim-soft/react-spring-web';

const Header = ({ images, currentIndex, onClose }) => {
  return (
    <header className="z-10 cursor-auto flex justify-end px-1 py-4 text-white">
      <div className="flex justify-between align-center mx-2" style={{ width: '117px' }}>
        <div className="flex flex-col justify-center">
          <span className="whitespace-nowrap text-xl" style={{ minWidth: '60px' }}>
            {currentIndex + 1} / {images.length}
          </span>
        </div>
        <button className="h-full flex" style={{ borderLeft: '2px solid white' }} onClick={onClose}>
          <IoIosClose size={60}/>
        </button>
      </div>
    </header>
  );
};

const ArrowButton = ({ position, onClick, disabled }) => {
  const transitions = useTransition(!disabled, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) =>
    item && (
      <animated.div key={key} style={{ ...props, zIndex: 999 }}>
        <button
          className="absolute text-white"
          style={{
            left: position === 'left' ? 0 : 'unset',
            right: position === 'right' ? 0 : 'unset',
          }}
          type="button"
          onClick={onClick}
        >
          {position === 'left' ? <IoIosArrowBack size={60}/> : <IoIosArrowForward size={60}/>}
        </button>
      </animated.div>
    ),
  );
};

const Lightbox = ({ isOpen, onClose, images, setCurrentIndex, currentIndex }) => {

    const gotoPrevious = () => currentIndex > 0 && setCurrentIndex(currentIndex - 1);

    const gotoNext = () =>
      currentIndex + 1 < images.length && setCurrentIndex(currentIndex + 1);

    const imageInfo = images.map((image) => ({ alt: image.alt, src: imageUrlFor(image).url() }));

    return (
      isOpen ? <SpringLightbox
        isOpen={isOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={imageInfo}
        currentIndex={currentIndex}
        renderHeader={() => (<Header images={images} currentIndex={currentIndex} onClose={onClose}/>)}
        renderPrevButton={({ canPrev }) => (<ArrowButton position="left" onClick={gotoPrevious} disabled={!canPrev}/>)}
        renderNextButton={({ canNext }) => (<ArrowButton position="right" onClick={gotoNext} disabled={!canNext}/>)}
        /* Add your own UI */
        // renderFooter={() => (<CustomFooter />)}
        // renderImageOverlay={() => (<ImageOverlayComponent >)}

        /* Add styling */
        // className="cool-class"
        style={{ background: 'rgba(0, 0, 0, 0.7)' }}
        /* Handle closing */
        onClose={onClose}

        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        // pageTransitionConfig={{
        //   from: { transform: "scale(0.75)", opacity: 0 },
        //   enter: { transform: "scale(1)", opacity: 1 },
        //   leave: { transform: "scale(0.75)", opacity: 0 },
        //   config: { mass: 1, tension: 320, friction: 32 }
        // }}
      /> : null
    );
  }
;

export default Lightbox;
