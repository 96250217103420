import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Logo from './logo/square_carrot';
import { doNavigate } from './CTALink';

const Marker = ({ size, lat, lng }) => {
  const padding = Math.round(size / 12);
  return (
    <div
      style={{
        position: 'relative',
        width: `${size}px`,
        height: `${size}px`,
        left: `${size / -2}px`,
        top: `${size * -1 - padding}px`,
        padding: `${padding}px`,
        backgroundColor: '#161615',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column'
      }}
      className="cursor-pointer"
      onClick={() => doNavigate('https://www.google.com/maps/place/De+Hoek+4,+7843+PL+Erm/')}
      lat={lat}
      lng={lng}
    >
      <Logo style={{ width: 'inherit', paddingRight: `${padding * 2}px` }} color="#fff" stroke={10}/>
      <span
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          width: 0,
          height: 0,
          margin: 'auto',
          borderLeft: `${padding}px solid transparent`,
          borderRight: `${padding}px solid transparent`,
          borderTop: `${padding}px solid #161615`,
        }}
      />
    </div>
  );
};

export class Map extends Component {
  static defaultProps = {
    center: {
      lat: 52.7551575,
      lng: 6.8154591,
    },
    zoom: 15,
  };

  constructor(props) {
    super(props);

    this.state = {
      zoom: 15,
    };
  }

  _onBoundsChange(_, zoom) {
    if (zoom !== this.state.zoom) {
      this.setState((state) => {
        return { ...state, zoom };
      });
    }
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCGGkBNIwZPwLpYg-3CsCz13y0q4LG6pZ4' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onBoundsChange={this._onBoundsChange.bind(this)}
          options={() => ({ mapTypeControl: true })}
        >
          <Marker
            size={20 + Math.max(this.state.zoom * 3 - Math.max(0, 10 - this.state.zoom) * 2, 3)}
            lat={this.props.center.lat}
            lng={this.props.center.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
