import React from 'react';

import { NetlifyForm, Honeypot } from 'react-netlify-forms';

const ContactForm = ({ title }) => {
  const titleComponent = <h3
    className={`text-4xl font-semibold leading-none mb-6`}>{title}</h3>;

  return <div className="text-xl">
    {titleComponent}
    <NetlifyForm
      name='Contact' action='/'
      honeypotName='tags'
      onSuccess={(_, context) => context.formRef.current.reset()}
    >
      {({ handleChange, success, error }) => (
        <>
          <Honeypot/>
          {success && <p className="px-4 py-2 rounded-md mb-4 bg-orange-light">Bedankt, uw vraag is verstuurd!</p>}
          {error &&
          <p className="px-4 py-2 rounded-md mb-4 bg-orange-light border-red-500 border-2">Sorry, er is iets mis
            gegaan.
            Probeer uw vraag per email te sturen naar info@ermerhoek.nl</p>}
          <div className="max-w-xs mb-2">
            <label htmlFor="name" className="block text-lg">Naam:</label>
            <div>
              <input type="text" name="name" id="name" onChange={handleChange}
                     className="w-full border-2 focus:ring-orange-dark focus:border-orange-normal px-2 border-orange-light rounded-md"
                     required/>
            </div>
          </div>
          <div className="max-w-xs mb-2">
            <label htmlFor="email" className="block text-lg">Email:</label>
            <div>
              <input type="text" name="email" id="email" onChange={handleChange}
                     className="w-full border-2 focus:ring-orange-dark focus:border-orange-normal px-2 border-orange-light rounded-md"
                     required/>
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="message" className="block text-lg">Bericht:</label>
            <div>
              <textarea rows="4" name="message" id="message" onChange={handleChange}
                        className="w-full border-2 focus:ring-orange-dark focus:border-orange-normal px-2 border-orange-light rounded-md"
                        required/>
            </div>
          </div>
          <div>
            <button disabled={success} type="submit"
                    className={`${success ? 'bg-gray-300' : 'bg-orange'} font-wavehaus font-semibold text-white text-xl hover:underline rounded py-1 px-4 relative z-10`}>Verstuur
            </button>
          </div>
        </>
      )}
    </NetlifyForm>
  </div>;
};

export default ContactForm;
