import React from 'react';
import Image from './Image';

const ImageRow = (illustration) => {
  return (
    <div className="flex flex-wrap pb-6">
      <div className="w-full sm:w-1/1">
        <Image illustration={!illustration.disabled && illustration.image} />
      </div>
    </div>
  );
};

export default ImageRow;
