import React from 'react';

export default function Beetroot({ color = '#161615', maskColor='none', ...props }) {
  return (
    <svg
      {...props}
      width="391"
      height="576"
      viewBox="0 0 391 576"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M75.44 369.17C74.55 370.89 9.56001 397 3.05001 455.25C-3.45999 513.5 31.76 574.05 128.27 574.05C263.27 574.05 276.05 491.38 276.08 471.47C276.14 420.47 238.24 371.31 181.67 370.34C250.18 292.34 358.15 2.66992 358.15 2.66992C358.15 2.66992 252 21.5099 204 160.23C156 298.95 217.7 326.88 277.64 316.6C358.31 310.8 432.64 70.1099 358.13 2.66992" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M181.67 370.34C177.44 361.42 63.0498 39.4499 63.0498 39.4499C63.0498 39.4499 9.04976 87.2699 42.9398 196.08C74.0798 275.81 125.73 292.23 149.39 281.58C189.39 263.58 191.55 202.87 182.6 154.78C156.2 22.5799 63.5998 39.4499 63.5998 39.4499" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M77 368L75.5 367V362H176.5L180 371L181 372.5L76 371L77 369.5V368Z" fill={maskColor} />
    </svg>
  );
}
