import React from 'react';

export default function Leek({ color = '#161615', maskColor='none', ...props }) {
  return (
    <svg
      {...props}
      width="225"
      height="571"
      viewBox="0 0 228 563"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M77.5101 362.81L2.1001 30.0999C2.1001 30.0999 25.0001 3.50993 70.9001 21.9999C78.8301 46.7299 99.8201 130.88 118 204.93" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M93.2002 104.26C92.5802 101.8 94.5202 17.61 93.2002 16.65C91.8802 15.69 124.49 -16.57 161.83 16.65C158.41 45.9 157.21 65.55 157.21 65.55" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M152.67 362.81L161.19 568.2" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M142.38 405.59C148.52 380.12 217.69 91.8602 222.38 76.9102C203.97 57.6102 172.07 51.7702 152.61 68.9102C146.18 96.9102 77.48 364.47 77.48 364.47V566.81" stroke={color} strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M77 568.5L76 568V571H163V569L162.5 569.5L161 570L160 569.5L159.5 568.5V566L159 564H79V567V568L78 568.5H77Z"
        fill={maskColor}
      />
    </svg>
  );
}
