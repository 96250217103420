import React from 'react';
import CTA from './cta';

const CTASection = (props) => (
  <section className="sm:container sm:mx-auto sm:w-11/12 text-center py-6 mb-12">
    <CTA {...props} />
  </section>
);

export default CTASection;
