import React from 'react';
import PortableText from './portableText';
import CTALink from './CTALink';

const buttonColorClasses = ['bg-orange', 'bg-purple', 'bg-green'];

const CTA = ({ label, title, body, ctas }) => (
  <>
    {title && (
      <h3 className="w-full my-2 text-4xl font-semibold leading-tight text-center text-white">
        {title}
      </h3>
    )}

    {body && (
      <div className="my-4 text-3xl leading-tight inline">
        <PortableText blocks={body} />
      </div>
    )}

    <div className="flex flex-col md:flex-row">
      {(ctas || [])
        .filter((c) => !c.disabled)
        .map((c, i) => (
          <div className="flex-1 text-gray-800 text-center py-2" key={`cta_${i}`}>
            <CTALink {...c} className={buttonColorClasses[i % buttonColorClasses.length]} />
          </div>
        ))}
    </div>
  </>
);

export default CTA;
