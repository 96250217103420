import React from 'react';
import PortableText from './portableText';
import Image from './Image';
import CTA from './cta';

const InfoRow = ({ illustration, flipped, title, text, ctas }) => {
    const image = !illustration?.disabled && illustration?.image;

    const textSizeClass = image ? 'sm:w-3/5' : 'sm:w-1/1';
    const imageSizeClass = image ? 'sm:w-2/5' : 'sm:w-1/1';

    const titleComponent = <h3
      className={`text-4xl font-semibold leading-none mb-6 ${image ? '-ml-3' : ''}`}>{title}</h3>;
    const contentComponent = (
      <>
        {titleComponent}
        <div className="text-2xl mb-8 inline text-gray-800">
          <PortableText blocks={text}/>
        </div>
        {ctas && <CTA className="align-bottom" {...ctas}/>}
      </>
    );

    return !image ? (
      <div className="px-6 max-w-5xl lg:mx-auto">{contentComponent}</div>
    ) : flipped ? (
      <div className="flex flex-wrap pb-6">
        {image && (
          <div className={`w-full ${imageSizeClass}`}>
            <Image image={image} aspectRatio={1}/>
          </div>
        )}
        <div className={`w-full ${textSizeClass} sm:pl-8 sm:px-0 px-4`}>
          <div className="sm:pl-3">{contentComponent}</div>
        </div>
      </div>
    ) : (
      <div className="flex flex-wrap pb-6 flex-col-reverse sm:flex-row">
        <div className={`w-full ${textSizeClass} sm:pr-8 sm:px-0 px-4`}>
          <div className="sm:pl-3">{contentComponent}</div>
        </div>
        {image && (
          <div className={`w-full ${imageSizeClass}`}>
            <Image image={image} aspectRatio={1}/>
          </div>
        )}
      </div>
    );
  }
;

export default InfoRow;
