import React from 'react';
import Logo from './logo/full';
import { useMedia } from '../lib/useMedia';

const LogoRow = () => {
  const bigText = useMedia(['(min-width: 1024px)'], [false], true);

  return (
    <div className="pb-8 md:container mx-auto justify-center hidden md:flex">
        <Logo className="w-1/3" bigText={bigText} subText/>
    </div>
  );
};

export default LogoRow;
