import React from 'react';

import InfoRow from './infoRow';
import ImageRow from './imageRow';
import InfoRowIllustration from './InfoRowIllustration';
import CTA from './cta';
import Gallery from './gallery';
import ContactForm from './contactForm';

const InfoRows = ({ title, illustration, background, rows, columns, alignment, ...otherProps }) => {
  const backgroundColor =
    background && background.color && background.color !== 'rgba(255, 255, 255, 0)'
      ? background.color
      : undefined;
  const illustrationKind = illustration && illustration.kind !== 'none' && illustration.kind;

  const contentRows = (rows || [])
    .filter((r) => !r.disabled)
    .map((r, i) => {
      switch (r._type) {
        case 'textWithTitle':
        case 'textWithIllustration':
          if (r.title && r.title.toLowerCase() === 'contactformulier') {
            return <ContactForm key={r._key} {...r}/>;
          }
          return <InfoRow key={r._key} {...r} flipped={!r.flip}/>;
        case 'illustration':
          return <ImageRow key={r._key} {...r} flipped={!r.flip}/>;
        case 'ctaPlug':
          return <CTA key={r._key} {...r} />;
        case 'gallery':
          return <Gallery key={r._key} {...r} />;
        default:
      }
    })
    .map((row) => (
      <div key={row.key} className={` ${columns > 1 ? `w-full md:w-1/${columns}` : 'flex-basis'}`}>
        {row}
      </div>
    ));

  let textAlign = '';
  switch (alignment && alignment.textAlignment) {
    case 'left':
      textAlign = 'text-left';
      break;
    case 'right':
      textAlign = 'text-right';
      break;
    case 'center':
      textAlign = 'text-center';
      break;
  }

  const content = (
    <>
      <h1 className={`w-full px-6 text-5xl font-bold leading-tight ${textAlign}`}>{title}</h1>
      <div className={`flex flex-wrap ${textAlign}`}>{contentRows}</div>
    </>
  );

  if (illustrationKind && illustrationKind !== 'none') {
    return (
      <InfoRowIllustration kind={illustrationKind} backgroundColor={backgroundColor}>
        {content}
      </InfoRowIllustration>
    );
  }

  return (
    <section
      className={`lg:container md:flex relative lg:m-auto py-8 ${backgroundColor ? 'text-white' : ''}`}
      style={{ backgroundColor }}
    >
      <div className="my-8 px-8 text-center w-full">{content}</div>
    </section>
  );
};

export default InfoRows;
