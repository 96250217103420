import React from 'react';
import BeetrootMask from './logo/mask/beetroot';
import CarrotMask from './logo/mask/carrot';
import LeekMask from './logo/mask/leek';

const InfoRowIllustration = ({ kind, backgroundColor, children, ...props }) => {
  switch (kind) {
    case 'carrot':
      return (
        <section
          className={`lg:container flex mx-auto relative py-8 mt-16 mb-12 w-screen ${
            backgroundColor ? 'text-white' : ''
          }`}
          style={{ backgroundColor }}
        >
          <div
            className="h-full w-full shadow-bottom-xs md:shadow-bottom-sm"
            // style={{ boxShadow: 'black 0px -0.15em 0px 0px inset' }}
          >
            <div className="flex mr-8 relative h-full" style={{ minHeight: '100px' }}>
              <div className="my-8 pl-8 w-10/12 md:w-9/12 xl:w-10/12">
                {children}
              </div>
              <div className="w-2/12 md:w-3/12 xl:w-2/12 h-full pt-24 -mb-px box-content absolute right-0 bottom-0 bg-contain bg-no-repeat bg-right-bottom">
                <CarrotMask
                  className="absolute w-auto -mb-px h-auto max-h-full max-w-full bottom-0 right-0"
                  maskColor={backgroundColor}
                />
              </div>
            </div>
          </div>
        </section>
      );
    case 'leek':
      return (
        <section
          className={`lg:container flex mx-auto relative py-8 mt-16 mb-12 w-screen ${
            backgroundColor ? 'text-white' : ''
          }`}
          style={{ backgroundColor }}
        >
          <div
            className="h-full w-full shadow-bottom-xs md:shadow-bottom-sm"
            // style={{ boxShadow: 'black 0px -0.15em 0px 0px inset' }}
          >
            <div className="flex mr-8 relative h-full" style={{ minHeight: '100px' }}>
              <div className="my-8 pl-8 w-10/12 md:w-9/12 xl:w-10/12 md:pr-0">
                {children}
              </div>
              <div className="w-2/12 md:w-3/12 xl:w-2/12 h-full pt-24 box-content absolute -mb-px right-0 bottom-0 bg-contain bg-no-repeat bg-right-bottom">
                <LeekMask
                  className="absolute w-auto h-auto max-h-full max-w-full bottom-0 right-0"
                  maskColor={backgroundColor}
                />
              </div>
            </div>
          </div>
        </section>
      );
    case 'beetroot':
      return (
        <section
          className={`lg:container flex mx-auto relative py-8 mb-24 mt-12 w-screen ${
            backgroundColor ? 'text-white' : ''
          }`}
          style={{ backgroundColor }}
        >
          <div
            className="h-full w-full shadow-bottom-xs md:shadow-bottom-sm"
            // style={{ boxShadow: 'black 0px -0.15em 0px 0px inset' }}
          >
            <div className="flex mr-8 relative h-full" style={{ minHeight: '229px' }}>
              <div className="my-8 pl-8 w-10/12 md:w-9/12 xl:w-10/12">
                {children}
              </div>

              <div className="w-2/12 h-full top-0 md:w-3/12 xl:w-2/12 absolute right-0 bottom-0 bg-contain bg-no-repeat bg-right-bottom pb-beetroot-sm md:pb-beetroot-md xl:pb-beetroot-sm box-content">
                <BeetrootMask
                  className="absolute w-auto h-auto max-h-full max-w-full bottom-0 right-0"
                  maskColor={backgroundColor}
                />
              </div>
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
};

export default InfoRowIllustration;
