import React, { useState } from 'react';
import PortableText from './portableText';
import Image from './Image';
import Lightbox from './lightbox';

const Gallery = ({ title, body, illustrations }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setOpen] = useState(0);

  const images = (illustrations || [])
    .filter((illustration) => !illustration.disabled && illustration.image)
    .map(({ image }) => image);

  return (
    <>
      {title && (
        <h3 className="w-full my-2 text-4xl font-semibold leading-tight text-center text-white">
          {title}
        </h3>
      )}

      {body && (
        <div className="my-4 text-3xl leading-tight inline">
          <PortableText blocks={body} />
        </div>
      )}

      <div className="flex flex-row">
        {images.map((image, i) => (
          <div className="relative w-1/3 m-4" key={i}>
            <Image
              image={image}
              aspectRatio={1}
              className="px-1 transform md:hover:scale-102 transition-scale ease-linear duration-100 delay-100"
              style={{ marginBottom: '-1em' }}
              onClick={() => (setCurrentIndex(i), setOpen(true))}
            />
          </div>
        ))}
      </div>
      <Lightbox
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        images={images}
      />
    </>
  );
};

export default Gallery;
